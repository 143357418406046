.driverDownload {
    height: 100vh;
    .background {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .section {
            width: 100%;
            height: 100%;
            position: relative;

            .container {
                width: 45%;
                margin: 0;
                padding: 0% 2%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .information {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2%;
                    .logo {
                        width: 7%
                    }
                    .sentInfoText {
                        font-family: 'Open Sans';
                        font-weight: 400;
                        font-size: 0.9vw;
                        color: #6DCDEB;
                    }
                }
                .card {
                    width: 100%;
                    height: max-content;
                    background-color: #fff;
                    box-shadow: 0 -1px 34px 0 #7b7b86ab;;
                    border-radius: 15px;
                    text-align: center;
                    padding-bottom: 9%;
                    position: relative;
                    .Image {
                        width: 50%;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -35%);
                    }
                    .title {
                        padding-top: 17%;
                        font-size: 1.7vw;
                        font-family: 'Open Sans';
                        font-weight: 800;
                    }
                    .subTitle {
                        font-size: 1vw;
                        font-family: 'Open Sans';
                        font-weight: 400;
                        color: #6E6E6E;
                    }
                    .subTitleConatiner {
                        margin-top: 3%;
                    }
                }
                .copyright {
                    color: #fff;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 0.8vw;
                    text-align: center;
                    margin-top: 3%;
                }
            }
        }
    }
    .downloadContainer {
        display: flex;
    }
    .riderContainer {
        width: 50%;
        margin-top: 5%;
    }
    .driverContainer {
        width: 100%;
        margin-top: 5%;
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        outline: none;
        border: none;
    }
    .button {
        background-color: #11ADDF;
        color: white;
        width: 57%;
        height: 43px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        margin-top: 4%;
        outline: none;
        border: none;
    }
}

@media only screen and (max-width: 899px) {
    .driverDownload {
        .background {
            .section {
                .container {
                    width: 85%;
                    top: 40%;
                    transform: translate(-50%, -40%);
                    .information {
                        .logo {
                            width: 8%
                        }
                        .sentInfoText {
                            font-size: 2vw;
                        }
                    }
                    .card {
                        .title {
                            font-size: 3.5vw;
                        }
                        .subTitle {
                            font-size: 2vw;
                        }
                    }
                    .copyright {
                        font-size: 1.5vw;
                    }
                }
            }
        }
        .downloadContainer {
            display: block;
        }
        .riderContainer {
            width: 100%;
        }
        .driverContainer {
            width: 100%;
        }
        .button {
            background-color: #11ADDF;
            color: white;
            width: 43%;
            height: 34px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: none;
            font-weight: 600;
            font-size: 0.5rem;
            outline: none;
            border: none;
        }
        .buttonContainer {
            display: flex;
            justify-content: center;
            outline: none;
            border: none;
        }
        .driver {
            width: 37%;
        }
    }

}
